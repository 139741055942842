import React, {useState, useEffect} from "react"
import SEO from "../seo"
import { useSelector } from "react-redux"

export const MesCotisations = props => {
  const cotisations = useSelector(s => s.identifiants && s.identifiants.identifiants && s.identifiants.identifiants.subscription)
  const [limit, setLimit]   = useState(100)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if(props.dashboard){
        setLimit(3)
      }
    }
  }, [props])

  return (
    <>      
      <SEO title="Mon espace personnel" />
      <div className="box">
        <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB" }}>
          {cotisations && cotisations.length > 0 ? (
            <>
              <div style={{ borderLeft: "0" }} className="commandes header column">
                Date
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column">
                Total
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column">
                Moyen de paiement
              </div>
            </>
          ) : (
            <span className="subtitlte is-3">Aucune cotisation</span>
          )}
        </div>

        {cotisations && cotisations.slice(0,limit).map((cot, index) => (
          <div className="columns commandes line" key={index}>
            <div className="column ">
              <span className="is-hidden-desktop">Date :&nbsp;</span>
              {new Intl.DateTimeFormat('fr-FR').format(new Date(cot.dCreate))}
            </div>
            <div className="column ">
              <span className="is-hidden-desktop">Total :&nbsp;</span>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(cot.amount))}
            </div>
            <div className="column ">
              <span className="is-hidden-desktop">Moyen de paiement :&nbsp;</span>
              {cot.label}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default MesCotisations
