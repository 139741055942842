import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons"

import groceriesIcon from '../images/icon/groceries.svg'
import { setZone, setPickupPoint } from "../actions/panier"
import DernieresCommandes from "../components/dernieresCommandes"
import { updatePaiement } from "../actions/auth"
import {logError} from "../services/log"

export const IndexPage = () => {
  const identifiants = useSelector(s => s.identifiants)
  const panier = useSelector(s => s.panierR)
  const dispatch = useDispatch()
  const [favoriteZoneId, setFavoriteZoneId] = useState("")
  const [favoriteGroupeId, setFavoriteGroupeId] = useState("")
  const [favoriteZoneName, setFavoriteZoneName] = useState("")
  const [favoriteZoneLibelle, setFavoriteZoneLibelle] = useState("")
  const [favoriteGroupeLibelle, setFavoriteGroupeLibelle] = useState("")
  const [showCotisation, setShowCotisation] = useState(false)
  const [msgPaiement, setMsgPaiement] = useState({msg:"",color:""})
  const [mailVldStatus, setMailVldStatus] = useState(0)
  const [attenteValidationBanque, setAttenteValidationBanque] = useState("");

  const isBrowser = typeof window !== "undefined"
  const actionUrl = isBrowser ? new URLSearchParams(window.location.search).get("action") : null
  const typeUrl = isBrowser ? new URLSearchParams(window.location.search).get("type") : null
  const mntUrl = isBrowser ? new URLSearchParams(window.location.search).get("Mt") : null
  const refUrl = isBrowser ? new URLSearchParams(window.location.search).get("Ref") : null
  const codeUrl = isBrowser ? new URLSearchParams(window.location.search).get("Erreur") : null
  const mntVads = isBrowser ? new URLSearchParams(window.location.search).get("vads_amount") : null
  const orderVads = isBrowser ? new URLSearchParams(window.location.search).get("vads_order_id") : null

  const [form, setForm] = useState({
    PBX_SITE: "1653422",
    PBX_RANG: "01",
    PBX_IDENTIFIANT: "691204897",
    PBX_TOTAL: 1500,
    PBX_DEVISE: "978",
    PBX_CMD: "",
    PBX_PORTEUR: identifiants.identifiants && identifiants.identifiants.customer_email ? identifiants.identifiants && identifiants.identifiants.customer_email : null,
    PBX_RETOUR: "Mt:M;Ref:R;Auto:A;Erreur:E;idtrans:S;sign:K",
    PBX_TIME: "",
    PBX_HASHKEY: "",
    PBX_HMAC: "",
    PBX_EFFECTUE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=effectue&type=cotisation",
    PBX_ANNULE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=annule&type=cotisation",
    PBX_REFUSE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=refuse&type=cotisation",
    PBX_REPONDRE_A: process.env.GATSBY_API_URL + "/public/notifications/cotis-transaction",
  })

  const dataGroupZone = useStaticQuery(graphql`
    query dataDashboard {
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_zone_id
            group_name
          }
        }
      }
      allMysqlZone {
        edges {
          node {
            zone_info
            zone_id
            zone_libelle
          }
        }
      }
    }
  `)
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (dataGroupZone && identifiants.identifiants !== null && identifiants.identifiants !== undefined) {
        let zoneIdSelected = parseInt(identifiants.identifiants.customer_zone_id)
        let zoneNameSelected = ""
        let zoneLibelleSelected = ""
        let groupeLibelleSelected = ""
        let groupeIdSelected = parseInt(identifiants.identifiants.customer_group)
        if (identifiants.identifiants.lastOrders && identifiants.identifiants.lastOrders.length > 0 && (!groupeIdSelected || !zoneIdSelected)) {
          zoneIdSelected = dataGroupZone.allMysqlGroupe.edges.find(legroupe => parseInt(legroupe.node.group_id) === parseInt(identifiants.identifiants.lastOrders[0].cmd_group_id))
          zoneIdSelected = zoneIdSelected ? zoneIdSelected.node.group_zone_id : null

          if (zoneIdSelected) {
            groupeIdSelected = identifiants.identifiants.lastOrders[0].cmd_group_id
          }
        }
        if (zoneIdSelected) {
          if (zoneIdSelected !== favoriteZoneId) setFavoriteZoneId(zoneIdSelected);
          zoneNameSelected = dataGroupZone.allMysqlZone.edges.find(lazone => parseInt(lazone.node.zone_id) === parseInt(zoneIdSelected))
          zoneNameSelected = zoneNameSelected ? zoneNameSelected.node.zone_info : null
          zoneLibelleSelected = dataGroupZone.allMysqlZone.edges.find(lazone => parseInt(lazone.node.zone_id) === parseInt(zoneIdSelected))
          zoneLibelleSelected = zoneLibelleSelected ? zoneLibelleSelected.node.zone_libelle : null
          console.log(dataGroupZone.allMysqlZone)
          if (zoneNameSelected !== favoriteZoneName) setFavoriteZoneName(zoneNameSelected);
          if (zoneLibelleSelected !== favoriteZoneLibelle) setFavoriteZoneLibelle(zoneLibelleSelected);
        }
        if (groupeIdSelected) {
          if (groupeIdSelected !== favoriteGroupeId) setFavoriteGroupeId(groupeIdSelected);
          groupeLibelleSelected = dataGroupZone.allMysqlGroupe.edges.find(legroupe => parseInt(legroupe.node.group_id) === parseInt(groupeIdSelected))
          groupeLibelleSelected = groupeLibelleSelected ? groupeLibelleSelected.node.group_name : null
          if (groupeLibelleSelected !== favoriteGroupeLibelle) setFavoriteGroupeLibelle(groupeLibelleSelected);
          if (!panier.pickupPoint && groupeIdSelected !== parseInt(panier.pickupPoint)) dispatch(setPickupPoint(groupeIdSelected));
        }

        if (identifiants.identifiants.lastOrders && identifiants.identifiants.subscription.length > 0) {
          let show = true
          let subthisyear = identifiants.identifiants.subscription.find((sub) => (new Date() - new Date(sub.dCreate)) / (1000 * 60 * 60 * 24) < 366)
          if (subthisyear !== null && subthisyear !== undefined) {
            show = false
          }
          // if(show !== showCotisation) setShowCotisation(show);
        } else {
          // if(true !== showCotisation) setShowCotisation(true);
        }
      }
    }
  }, [dataGroupZone, dispatch, identifiants, favoriteZoneName, favoriteZoneId, favoriteGroupeId, favoriteZoneLibelle, panier.pickupPoint, favoriteGroupeLibelle, showCotisation])

  useEffect(() => {
    let message = ""
    let couleur = ""
    if (typeof window !== "undefined") {
      if (actionUrl && !msgPaiement.msg) {
        if(typeUrl === "cotisation"){
          if(parseInt(codeUrl)>0 && actionUrl !== "annule"){
            message = `Problème avec votre paiement de cotisation`
            couleur = '#f19814'
          }else if(actionUrl === "effectue"){
            message = `Votre paiement d'un montant de ${parseFloat(mntUrl)/100}€  pour la cotisation a été effectué`
            couleur = '#4d9f16'
            updatePaiement(mntUrl,refUrl);
          }
        }else if(mntVads !== null && orderVads !== null){
          if(actionUrl === "effectue_vads"){
            message = `Votre paiement d'un montant de ${parseFloat(mntVads)/100}€  pour la commande ${orderVads} a été effectué`
            couleur = '#4d9f16'
            updatePaiement(mntVads,orderVads);
            setAttenteValidationBanque(orderVads);
          }
          else if (actionUrl !== "annule") {
            couleur = '#f19814'
            message = `Problème avec votre paiement de la commande n°${orderVads}`
          }
        }else{
          if(parseInt(codeUrl)>0){
            message = `Problème avec votre paiement de la commande n°${refUrl}`
            couleur = '#f19814'
          }else if(actionUrl === "effectue"){
            message = `Votre paiement d'un montant de ${parseFloat(mntUrl)/100}€  pour la commande ${refUrl} a été effectué`
            couleur = '#4d9f16'
            updatePaiement(mntUrl,refUrl);
            setAttenteValidationBanque(refUrl);
          }
        }
        setMsgPaiement({...msgPaiement,msg:message,color:couleur})
      }
    }
  }, [dispatch, actionUrl, mntUrl, codeUrl, refUrl, typeUrl, mntVads, orderVads])

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (showCotisation && identifiants.identifiants !== null && identifiants.identifiants !== undefined && !form.PBX_HMAC) {
        const url = process.env.GATSBY_API_URL + "/public/tofront/GetHmacCA"
        const cmd_id = moment(new Date()).format('YMMDHmmss') +"-"+identifiants.identifiants.customer_id
        var datetime = new Date()
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            admin_id: 0,
            PBX_SITE: form.PBX_SITE,
            PBX_RANG:form.PBX_RANG,
            PBX_IDENTIFIANT: form.PBX_IDENTIFIANT,
            PBX_TOTAL: form.PBX_TOTAL,
            PBX_DEVISE: form.PBX_DEVISE,
            PBX_CMD: cmd_id,
            PBX_PORTEUR: form.PBX_PORTEUR,
            PBX_REPONDRE_A: form.PBX_REPONDRE_A,
            PBX_RETOUR: form.PBX_RETOUR,
            PBX_EFFECTUE: form.PBX_EFFECTUE,
            PBX_ANNULE: form.PBX_ANNULE,
            PBX_REFUSE: form.PBX_REFUSE,
            PBX_TIME: datetime.toISOString(),
            PBX_HASHKEY: form.PBX_HASHKEY,
          }),
        })
          .then(response => response.json())
          .then(r => {
            setForm({
              ...form,
              PBX_HMAC: r,
              PBX_CMD: cmd_id,
              PBX_TIME: datetime.toISOString()
            })
          })
          .catch(error => {
            console.log("Request failed", error)
            logError('request paiement failed (dashboard)', {error, form, cmd_id})
          })
      }
    }
  }, [identifiants.identifiants, form, showCotisation])

  const sendMailVld = evt => {
    const url = process.env.GATSBY_API_URL + "/public/tofront/sentMailVldCpt"
    setMailVldStatus(1)
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: identifiants.identifiants.customer_id,
        customer_email: identifiants.identifiants.customer_email
      }),
    })
      .then(() => {
        setMailVldStatus(2)
      })
      .catch(error => {
        console.log("Request failed", error)
        logError('sendMailVld failed (dashboard)', {error, identifiants})
        setMailVldStatus(0)
      })
  }

  const DispatchPickup = () => {
    dispatch(setPickupPoint(favoriteGroupeId))
    dispatch(setZone(favoriteZoneId))
  }

  return (
    <>
      {identifiants.identifiants && identifiants.identifiants.currentCmdEndInput && identifiants.identifiants.currentCmdEndInput.map(livraison => (
        <div className="notification" style={{background: "#4d9f16", color: "#fff"}} key={livraison[0]+livraison[1]}>
          <p><FontAwesomeIcon icon={faCheck} className="mr-2 is-size-3" /> Vous avez confirmé votre commande pour la livraison <strong>{livraison[0]}</strong>.</p>
          <p>Vous pouvez modifier cette commande jusqu'au <strong>{livraison[1]}</strong> à minuit (votre panier sera automatiquement validé à cette date).</p>
        </div>
      ))}
      <div className="section pt-2">
        <div className="columns is-multiline">
          <div className="column is-6">
            <div><span className="has-text-weight-bold is-size-3 almond">{identifiants.identifiants && identifiants.identifiants.customer_firstname} {identifiants.identifiants && identifiants.identifiants.customer_lastname}</span> {identifiants.identifiants && identifiants.identifiants.customer_email} - <Link to="/apps/mon-compte/donneesPerso">Modifier mes données personnelles</Link></div>
            {favoriteGroupeLibelle ? <>
              <div><span className="almond is-size-4">Mon point de retrait :</span> {favoriteGroupeLibelle} - <Link to="/#points-retrait">Commander des produits dans un autre point de retrait</Link></div>
              <div><span className="almond is-size-4">Livré par :</span> {favoriteZoneLibelle}</div>
            </> : null}
          </div>
          <div className="column has-text-centered">
            {favoriteZoneId ? <Link to={`/producteur/${favoriteZoneId}-${favoriteZoneName}/produits`} onClick={() => DispatchPickup()} className="button is-success is-light is-large dreamland is-size-1" style={{ whiteSpace: "normal", lineHeight: "2.2rem", height: "auto"}}>
              <img src={groceriesIcon} style={{ width: 40, height: 40, position: 'relative', top: 12, marginRight: 15 }} alt="Produits" />
              {identifiants.identifiants && identifiants.identifiants.currentCmdEndInput && identifiants.identifiants.currentCmdEndInput.length ? 'Modifier ma commande' : 'Commander des produits'}
            </Link> : <Link to={`/#points-retrait`} className="button is-success is-light is-large dreamland is-size-1" style={{ whiteSpace: "normal", lineHeight: "2.2rem", height: "auto"}}>
              <img src={groceriesIcon} style={{ width: 40, height: 40, position: 'relative', top: 12, marginRight: 15 }} alt="Produits" />
              Choisir un point de retrait
            </Link>}
          </div>
        </div>
      </div>
      {identifiants.identifiants && identifiants.identifiants.customer_status && parseInt(identifiants.identifiants.customer_status) === 0 ?
        <div className="notification" style={{background: "#DF1818", color: "#fff"}} >
          Vous n'avez pas encore validé votre adresse email. Votre commande ne pourra pas être traitée tant que votre email n'aura pas été vérifié.<br /> Veuillez cliquer sur le lien reçu par email{mailVldStatus === 0 ? <> ou de demander le <button className="linkVld" onClick={() => sendMailVld()} style={{color: "#fff"}}><u>renvoi d'un nouveau mail de confirmation</u></button></> : (mailVldStatus === 1 ? <span>. <br /><br />Envoi en cours, veuillez patienter...</span>: <span>. <br /><br />Un lien de validation vient d'être ré-envoyé à votre adresse e-mail</span>)}.
        </div>
      :""}
      {actionUrl && msgPaiement.msg ?
        <div className="notification" style={{background: msgPaiement.color, color: "#fff"}} key={refUrl}>
          {msgPaiement.msg}
        </div>
      :""}
      <div className="section pattern-cross-dots pb-5 pt-1">
        <div className="columns is-multiline">
          <div className="column">
            <div className="box" style={{ minHeight: "27rem" }}>
              <h2 className="almond is-size-3 mb-4">Mes dernières commandes</h2>
                <DernieresCommandes attenteValidationBanque={attenteValidationBanque} />
            </div>
          </div>
          {showCotisation ?
            <div className="column">
              <div className="box" style={{ background: "#fff1c5" }}>
                <h2 className="almond is-size-3">Adhésion 2021 à l'association AgroBioConso</h2>
                <p>Vous appréciez de passer vos commandes sur AgroBioConso ?</p>
                <p>Vos adhésions sont essentielles. En l'absence de commission prélevée sur chaque transaction, les cotisations couvrent 100% des frais d'entretien du site Internet et garantissent l'indépendance et la pérennité de l'association.</p>
                <div className="has-text-centered">
                <form method='POST' action="https://tpeweb.e-transactions.fr/cgi/MYchoix_pagepaiement.cgi" >
                  <input type='hidden' name='PBX_SITE' value={form.PBX_SITE} />
                  <input type='hidden' name='PBX_RANG' value={form.PBX_RANG} />
                  <input type='hidden' name='PBX_IDENTIFIANT' value={form.PBX_IDENTIFIANT} />
                  <input type='hidden' name='PBX_TOTAL' value={form.PBX_TOTAL} />
                  <input type='hidden' name='PBX_DEVISE' value={form.PBX_DEVISE} />
                  <input type='hidden' name='PBX_CMD' value={form.PBX_CMD} />
                  <input type='hidden' name='PBX_PORTEUR' value={form.PBX_PORTEUR} />
                  <input type='hidden' name='PBX_REPONDRE_A' value={form.PBX_REPONDRE_A} />
                  <input type='hidden' name='PBX_RETOUR' value={form.PBX_RETOUR} />
                  <input type='hidden' name='PBX_EFFECTUE' value={form.PBX_EFFECTUE} />
                  <input type='hidden' name='PBX_ANNULE' value={form.PBX_ANNULE} />
                  <input type='hidden' name='PBX_REFUSE' value={form.PBX_REFUSE} />
                  <input type='hidden' name='PBX_HASH' value='SHA512' />
                  <input type='hidden' name='PBX_TIME' value={form.PBX_TIME} />
                  <input type='hidden' name='PBX_HMAC' value={form.PBX_HMAC} />
                  <button className="button dreamland is-size-3 is-warning" style={{ padding: "0.5rem 1rem", height: "auto" }} type='submit' value='Paiement en ligne'>Regler ma cotisation</button>
                </form>
                </div>
              </div>
            </div>
          : ""}
        </div>
      </div>
    </>
  )
}

export default IndexPage
