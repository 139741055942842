import React from "react"
import { graphql, useStaticQuery } from "gatsby" 
import SEO from "../components/seo"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faClock } from "@fortawesome/free-solid-svg-icons"
import { login } from "./../actions"

export const ChoixComptePage = props => {

  const dataGroup = useStaticQuery(graphql`
    query dataChoix{
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
          }
        }
      }
    }
  `) 

  const dispatch = useDispatch() 
  const identifiants = useSelector(s => s.identifiants)

  const handleChange = (i)=> {
    dispatch(login(identifiants.identifiants.slice(i,i+1)))
    navigate("/")
  }

  return (
    
    <>      
      <SEO title="Mon espace personnel" />
      <span className="title is-1 has-text-success m-4">Choissisez un compte</span>

      <div className="columns is-centered login">
        <div className="section column is-half couleurShadow">
        {identifiants.identifiants === null ? "" : identifiants.identifiants.map((id, i) => { 
          const thisGroup = dataGroup.allMysqlGroupe.edges.find(legroupe => parseInt(legroupe.node.group_zone_id) === parseInt(id.customer_zone_id) && parseInt(legroupe.node.group_id) === parseInt(id.customer_group));
          return (
            <div className="card mb-4">
              <header className="card-header">
                <p className="card-header-title">
                  Compte {i+1}
                </p>
              </header> 
              <div className="card-content">
                <div className="content">
                  <div className="columns">
                    <div className="column">
                      <p>Login : {id.customer_login}</p>
                      <p>Identité : {id.customer_firstname} {id.customer_lastname}</p>
                      <p>Email : {id.customer_email}</p>
                      <p>Adresse : {id.customer_address} {id.customer_zipcode} {id.customer_ville}</p>
                      <p>Téléphone : {id.customer_phone}</p>
                    </div>
                    <div className="column">
                      <p className="title is-6">Retrait des produits</p>
                      <div className="columns">
                        <div className="column is-one-fifth"><FontAwesomeIcon className='is-vcentered is-pulled-right' icon={faHome} size="2x" /></div>
                        <div className="column" style={{lineHeight:"10px"}}>
                          <p>{(thisGroup && thisGroup.node.group_person) || ""}</p>
                          <p>{(thisGroup && thisGroup.node.group_address) || ""}</p>
                          <p>{(thisGroup && thisGroup.node.group_ville) || ""}</p>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-one-fifth"><FontAwesomeIcon className='is-vcentered is-pulled-right' icon={faClock} size="2x" /></div>
                        <div className="column">
                          <p style={{textTransform:"capitalize"}}>{(thisGroup && thisGroup.node.group_horaire) || ""}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="card-footer">
                <button onClick={() => handleChange(i)} className="card-footer-item">Choisir</button>
              </footer>
            </div>
          );
        })}
        </div>
      </div>
    </>
  )
}

export default ChoixComptePage
