import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateInfos, unsubscribe } from "./../../actions/auth"
import { clearLoading } from "../../actions"
import MaskInput from 'react-maskinput'

export const MesDonneesPerso = props => {

  const dispatch = useDispatch()
  const identifiants = useSelector(s => (s.identifiants && s.identifiants.identifiants) || {})
  const loading = useSelector(s => s.loadingR)

  const [form, setForm] = useState({
    lastname: identifiants.customer_lastname,
    firstname: identifiants.customer_firstname,
    email: identifiants.customer_email,
    identifiant: identifiants.customer_login,
    adresse: identifiants.customer_address,
    codePostal: identifiants.customer_zipcode,
    ville: identifiants.customer_ville,
    telephone: identifiants.customer_phone,
    group: identifiants.customer_group,
    zone_id: identifiants.customer_zone_id,
    id: identifiants.customer_id,
    Nmdp: "",
    CNmdp: "",
  })

  useEffect(() => {
    dispatch(clearLoading(null))
  }, [dispatch])

  const handleUpdate = event => {
    if (event.target.name === "firstname" || event.target.name === "lastname") {
      setForm({ ...form, [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "") })
    } else {
      setForm({ ...form, [event.target.name]: event.target.value })
    }
  }

  const downloadTxtFile = event => {
    event.preventDefault()
    var text = "*** Données Personnelles ***\n\n"
    text += "Login : " + identifiants.customer_login + "\n"
    text += "Prénom : " + identifiants.customer_firstname + "\n"
    text += "Nom : " + identifiants.customer_lastname + "\n"
    text += "E-mail : " + identifiants.customer_email + "\n"
    text += "------------------------------\n"
    text += "*** Adresse ***\n"
    text += "Adresse : " + identifiants.customer_address + "\n"
    text += "Code postale : " + identifiants.customer_zipcode + "\n"
    text += "Ville : " + identifiants.customer_ville + "\n"
    text += "N° téléphone : " + identifiants.customer_phone + "\n"
    const element = document.createElement("a")
    const file = new Blob([text], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = "données_personnelles.txt"
    document.body.appendChild(element) //  for this to work in FireFox
    element.click()
  }

  const handleUnsubscribe = event => {
    event.preventDefault()

    if (window.confirm("Confirmez-vous souhaiter supprimer vos données personnelles de la base de données Agrobioconso ?")) {
      dispatch(unsubscribe(identifiants.customer_id, identifiants.customer_pass))
    }
  }

  const handleUploadfile = event => {
    event.preventDefault()
    const data = {}
    data.customer_id = form.id
    data.customer_firstname = form.firstname
    data.customer_lastname = form.lastname
    data.customer_email = form.email
    data.original_customer_email = identifiants.customer_email
    data.customer_login = form.identifiant
    data.customer_pass = identifiants.customer_pass
    data.customer_address = form.adresse
    data.customer_zipcode = form.codePostal
    data.customer_ville = form.ville
    data.customer_phone = form.telephone
    data.customer_group = form.group
    data.customer_zone_id = form.zone_id
    data.customer_pass_isrecrypt = identifiants.customer_pass_isrecrypt
    data.Nmdp = form.Nmdp
    data.CNmdp = form.CNmdp
    dispatch(updateInfos(data))
  }


  return (
    <div className="box">
      <form method="post" onSubmit={event => handleUploadfile(event)}>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label" htmlFor="identifiant">Identifiant&nbsp;:</label>
              <div className="control">
                <input type="text" id="identifiant" name="identifiant" value={form.identifiant} onChange={e => handleUpdate(e)} className="input" placeholder="Votre identifiant" />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="Nmdp">
                    Nouveau mot de passe&nbsp;:
                    </label>
                  <div className="control">
                    <input
                      type="password"
                      id="Nmdp"
                      name="Nmdp"
                      autoComplete="new-password"
                      onChange={e => handleUpdate(e)}
                      className={`input`}
                      placeholder="Votre nouveau mot de passe"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="CNmdp">
                    Confirmer le nouveau mot de passe&nbsp;:
                    </label>
                  <div className="control">
                    <input
                      type="password"
                      id="CNmdp"
                      autoComplete="new-password"
                      name="CNmdp"
                      onChange={e => handleUpdate(e)}
                      className={`input ${form.Nmdp && form.Nmdp !== form.CNmdp ? "is-danger" : ""}`}
                      placeholder="Confirmer nouveau mot de passe"
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="column">
            <div className="field">
              <label className="label" htmlFor="lastname">
                Nom&nbsp;:
                </label>
              <div className="control">
                <input type="text" id="lastname" name="lastname" value={form.lastname} onChange={e => handleUpdate(e)} className="input" placeholder="Votre nom" />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="firstname">
                Prénom&nbsp;:
                </label>
              <div className="control">
                <input type="text" id="firstname" name="firstname" value={form.firstname} onChange={e => handleUpdate(e)} className="input" placeholder="Votre prénom" />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label" htmlFor="adresse">
                Adresse&nbsp;:
                </label>
              <div className="control">
                <input type="text" id="adresse" name="adresse" value={form.adresse} onChange={e => handleUpdate(e)} className="input" placeholder="Votre adresse" />
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <div className="field">
                  <label className="label" htmlFor="codePostal">
                    Code postal &nbsp;:
                    </label>
                  <div className="control">
                    <MaskInput
                      id="codePostal" name="codePostal" value={form.codePostal} onChange={e => handleUpdate(e)} className="input" placeholder="Votre code postal"
                      alwaysShowMask mask={'00000'}
                      size={5}
                      showMask maskChar="_"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor="ville">
                    Ville &nbsp;:
                    </label>
                  <div className="control">
                    <input type="text" id="ville" name="ville" value={form.ville} onChange={e => handleUpdate(e)} className="input" placeholder="Votre ville" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label" htmlFor="email">
                Adresse email&nbsp;:
                </label>
              <div className="control">
                <input type="email" id="email" name="email" value={form.email} onChange={e => handleUpdate(e)} className="input" placeholder="Votre adresse email" />
              </div>
              {/* {loading.status === false && (
                  <div className="notification is-danger" style={{ marginTop: "1rem" }}>
                    L&apos;adresse email est déjà utilisée, veuillez en choisir une autre ou <Link to={`/connexion`}>vous connecter</Link>
                  </div>
                )} */}
            </div>
            <div className="field">
              <label className="label" htmlFor="telephone">
                Téléphone&nbsp;:
                </label>
              <div className="control">
                <MaskInput
                  id="telephone"
                  name="telephone"
                  onChange={e => handleUpdate(e)}
                  className="input"
                  placeholder="Votre téléphone"
                  value={form.telephone}
                  alwaysShowMask mask={'00 00 00 00 00'}
                  size={10}
                  showMask maskChar="_"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns has-text-right" style={{ marginTop: "2rem" }}>
          <div className="column has-text-left">
            <button className="button is-danger mr-3" onClick={event => handleUnsubscribe(event)} onKeyPress={() => { }} tabIndex={0}>
              {" "}
              Me désinscrire{" "}
            </button>
            <button className="button" onClick={event => downloadTxtFile(event)} onKeyPress={() => { }} tabIndex={0}>
              {" "}
              Télécharger mes données personnelles{" "}
            </button>
          </div>
          <div className="column">
            {loading.loading ? <button className="button is-loading">Envoi en cours...</button> :
              <input type="submit" value="Enregistrer" disabled={form.Nmdp && (form.Nmdp !== form.CNmdp) ? "is-danger" : ""} className="button is-primary is-large" />}
          </div>
        </div>
      </form>

      {loading.status === true ? <div className="notification is-success mt-2">Information mise à jour avec succès.</div> : ""}
      {loading.status === false ? (
        <div className="notification is-danger mt-2">
          {loading.message === "mailused" ? "L'adresse mail est déjà utilisée." : loading.message}
        </div>
      ) : (
          ""
        )}
    </div>
  )

}

export default MesDonneesPerso

