import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSelector } from "react-redux"
import moment from 'moment'
import { createHmac } from 'crypto'
import {logError} from "../services/log"

export const PaiementCmd = props => {
  const dataGroup = useStaticQuery(graphql`
    query {
      allMysqlZone {
        nodes {
          zone_id
          administrator_id
          administrator_ca_enable
          administrator_ca_hash
          administrator_ca_identifiant
          administrator_ca_rang
          administrator_ca_site
        }
      }
    }
  `)

  const identifiants = useSelector(s => s.identifiants.identifiants)
  const [form, setForm] = useState({
    PBX_SITE: "",
    PBX_RANG: "",
    PBX_IDENTIFIANT: "",
    PBX_TOTAL: "",
    PBX_DEVISE: "978",
    PBX_CMD: "",
    PBX_PORTEUR: identifiants && identifiants.customer_email ? identifiants.customer_email : "",
    PBX_RETOUR: "Mt:M;Ref:R;Auto:A;Erreur:E;idtrans:S;sign:K",
    PBX_TIME: "",
    PBX_HASHKEY: "",
    PBX_HMAC: "",
    PBX_EFFECTUE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=effectue",
    vads_EFFECTUE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=effectue_vads",
    PBX_ANNULE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=annule",
    PBX_REFUSE: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=refuse",
    PBX_REPONDRE_A: process.env.GATSBY_API_URL + "/public/notifications/e-transaction-api",
    vads_cust_id: identifiants && identifiants.customer_id ? identifiants.customer_id : "",
    vads_cust_last_name: identifiants && identifiants.customer_lastname ? identifiants.customer_lastname : "",
    vads_cust_first_name: identifiants && identifiants.customer_firstname ? identifiants.customer_firstname : "",
    vads_site_id: "",
    vads_trans_date: "",
    vads_trans_id: "",
    vads_url_cancel: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=annule",
    vads_url_error: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=refuse",
    vads_url_refused: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=refuse",
    vads_url_sucess: process.env.GATSBY_FRONT_URL + "apps/dashboard?action=effectue_vads",
    vads_mode: identifiants && identifiants.customer_id === "1" ? "TEST" : "PRODUCTION", //PRODUCTION / TEST
  })
  const zone = dataGroup.allMysqlZone.nodes.find(Lazone => parseInt(Lazone.zone_id) === parseInt(props.zoneId));

  useEffect(() => {
    if (typeof window !== "undefined" && zone && (!form.PBX_CMD || form.PBX_CMD !== props.cmdId)) {
      const TheTotal = Math.round(props.total * 100)
      if (zone.administrator_ca_enable === 1) {
        const url = process.env.GATSBY_API_URL + "/public/tofront/GetHmacCA"
        var datetime = new Date()
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            admin_id: zone.administrator_id,
            PBX_SITE: zone.administrator_ca_site,
            PBX_RANG: zone.administrator_ca_rang,
            PBX_IDENTIFIANT: zone.administrator_ca_identifiant,
            PBX_TOTAL: TheTotal,
            PBX_DEVISE: form.PBX_DEVISE,
            PBX_CMD: props.cmdId,
            PBX_PORTEUR: form.PBX_PORTEUR,
            PBX_REPONDRE_A: form.PBX_REPONDRE_A,
            PBX_RETOUR: form.PBX_RETOUR,
            PBX_EFFECTUE: form.PBX_EFFECTUE,
            PBX_ANNULE: form.PBX_ANNULE,
            PBX_REFUSE: form.PBX_REFUSE,
            PBX_TIME: datetime.toISOString(),
            PBX_HASHKEY: zone.administrator_ca_hash,
          }),
        })
          .then(response => response.json())
          .then(r => {
            setForm({
              ...form,
              PBX_HMAC: r,
              PBX_SITE: zone.administrator_ca_site,
              PBX_RANG: zone.administrator_ca_rang,
              PBX_IDENTIFIANT: zone.administrator_ca_identifiant,
              PBX_TOTAL: TheTotal,
              PBX_CMD: props.cmdId,
              PBX_TIME: datetime.toISOString()
            })
          })
          .catch(error => {
            console.log("Request failed", error)
            logError('gethmacca catch', {error, form})
          })
      } else if (zone.administrator_ca_enable === 2) {
        let key = '';

        if (form.vads_cust_id === "1") { // user test systempay
          key = '1103126505377191';
        }
        else {
          key = zone.administrator_ca_hash; //production
          /*    key = '1103126505377191'; //test */
        }

        const datetime = moment(new Date()).utc().format('YMMDDHHmmss')
        const microtime = moment(new Date()).utc().format('HHmmss')
        const msg = "INTERACTIVE+" + TheTotal + "+" + form.vads_mode + "+978++++" + form.PBX_PORTEUR
          + "+" + form.vads_cust_first_name + "+" + form.vads_cust_id + "+" + form.vads_cust_last_name + "+"
          + "+PRIVATE+" + props.cmdId + "+PAYMENT+SINGLE+Une erreur est survenue. Vous allez être redirigé vers le site AGROBIO CONSO+30+Bravo et merci. Vous allez être redirigé vers le site AGROBIO CONSO+20"
          + "+GET+" + zone.administrator_ca_site + "+" + datetime + "+" + microtime
          + "+" + form.vads_url_cancel + "+" + form.vads_url_error + "+" + form.vads_url_refused + "+" + form.vads_url_sucess
          + "+V2+"+key;

        const hmac = createHmac("sha256", Buffer.from(key, 'utf8')).update(msg).digest().toString('base64');
        setForm({
          ...form,
          PBX_HMAC: hmac,
          PBX_SITE: zone.administrator_ca_site,
          PBX_RANG: zone.administrator_ca_rang,
          PBX_IDENTIFIANT: zone.administrator_ca_identifiant,
          PBX_TOTAL: TheTotal,
          PBX_CMD: props.cmdId,
          PBX_TIME: datetime,
          vads_trans_id: microtime,
        })
      }
    }
  }, [props.cmdId, form, props.total, zone])

  return (
    <>
    {
      zone ? (zone.administrator_ca_enable === 1 ? (
        <form method='POST' action="https://tpeweb.e-transactions.fr/cgi/MYchoix_pagepaiement.cgi">
        <input type='hidden' name='PBX_SITE' value={form.PBX_SITE} />
        <input type='hidden' name='PBX_RANG' value={form.PBX_RANG} />
        <input type='hidden' name='PBX_IDENTIFIANT' value={form.PBX_IDENTIFIANT} />
        <input type='hidden' name='PBX_TOTAL' value={form.PBX_TOTAL} />
        <input type='hidden' name='PBX_DEVISE' value={form.PBX_DEVISE} />
        <input type='hidden' name='PBX_CMD' value={form.PBX_CMD} />
        <input type='hidden' name='PBX_PORTEUR' value={form.PBX_PORTEUR} />
        <input type='hidden' name='PBX_REPONDRE_A' value={form.PBX_REPONDRE_A} />
        <input type='hidden' name='PBX_RETOUR' value={form.PBX_RETOUR} />
        <input type='hidden' name='PBX_EFFECTUE' value={form.PBX_EFFECTUE} />
        <input type='hidden' name='PBX_ANNULE' value={form.PBX_ANNULE} />
        <input type='hidden' name='PBX_REFUSE' value={form.PBX_REFUSE} />
        <input type='hidden' name='PBX_HASH' value='SHA512' />
        <input type='hidden' name='PBX_TIME' value={form.PBX_TIME} />
        <input type='hidden' name='PBX_HMAC' value={form.PBX_HMAC} />
        <button className="button mt-5 is-info" type='submit' value='Paiement en ligne'>Paiement en ligne</button>
      </form>
      ) : (zone.administrator_ca_enable === 2 ? (
        <form method='POST' action="https://paiement.systempay.fr/vads-payment/">
          <input type='hidden' name='vads_action_mode' value='INTERACTIVE' />
          <input type='hidden' name='vads_amount' value={form.PBX_TOTAL} />
          <input type='hidden' name='vads_ctx_mode' value={form.vads_mode} />
          <input type='hidden' name='vads_currency' value='978' />
          <input type='hidden' name='vads_cust_address' value="" />
          <input type='hidden' name='vads_cust_cell_phone' value="" />
          <input type='hidden' name='vads_cust_city' value="" />
          <input type='hidden' name='vads_cust_email' value={form.PBX_PORTEUR} />
          <input type='hidden' name='vads_cust_first_name' value={form.vads_cust_first_name} />
          <input type='hidden' name='vads_cust_id' value={form.vads_cust_id} />
          <input type='hidden' name='vads_cust_last_name' value={form.vads_cust_last_name} />
          <input type='hidden' name='vads_cust_phone' value="" />
          <input type='hidden' name='vads_cust_status' value='PRIVATE' />
          <input type='hidden' name='vads_order_id' value={form.PBX_CMD} />
          <input type='hidden' name='vads_page_action' value='PAYMENT' />
          <input type='hidden' name='vads_payment_config' value='SINGLE' />
          <input type='hidden' name='vads_redirect_error_message' value='Une erreur est survenue. Vous allez être redirigé vers le site AGROBIO CONSO' />
          <input type='hidden' name='vads_redirect_error_timeout' value='30' />
          <input type='hidden' name='vads_redirect_success_message' value='Bravo et merci. Vous allez être redirigé vers le site AGROBIO CONSO' />
          <input type='hidden' name='vads_redirect_success_timeout' value='20' />
          <input type='hidden' name='vads_return_mode' value='GET' />
          <input type='hidden' name='vads_site_id' value={form.PBX_SITE} />
          <input type='hidden' name='vads_trans_date' value={form.PBX_TIME} />
          <input type='hidden' name='vads_trans_id' value={form.vads_trans_id} />
          <input type='hidden' name='vads_url_cancel' value={form.vads_url_cancel} />
          <input type='hidden' name='vads_url_error' value={form.vads_url_error} />
          <input type='hidden' name='vads_url_refused' value={form.vads_url_refused} />
          <input type='hidden' name='vads_url_sucess' value={form.vads_url_sucess} />
          <input type='hidden' name='vads_version' value='V2' />
          <input type='hidden' name='signature' value={form.PBX_HMAC} />
          <button className="button mt-5 is-info" type='submit' value='Paiement en ligne'>Paiement en ligne</button>
        </form>
      ) : null)) : null
    }
    </>
  )
}

export default PaiementCmd
