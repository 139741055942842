import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"

import { clearLoading, showLoading } from "../../actions/index"
import {logError} from "../../services/log"

export const MesCommandes = () => {
  const identifiants = useSelector(s => s.identifiants)
  const dispatch = useDispatch()
  const [orders, setOrders] = useState([]);
  const today = (new Date()).toISOString().substr(0, 10);

  const GetStatus = (cmd_status, cmd_saisie_livr, payment_status, livr_date) => {
    if (payment_status !== null) {
      return payment_status
    }
    if (cmd_status === "0") {
      return "Panier non confirmé"
    }
    if (cmd_saisie_livr === "0") {
      if (livr_date < today) {
        return "";
      }
      return "Commandée"
    }
    if (cmd_saisie_livr === "1") {
      if (livr_date < today) {
        return "Livrée";
      }
      return "Préparée";
    }
  }

  useEffect(() => {
    dispatch(showLoading())
    fetch(process.env.GATSBY_API_URL + "/public/tofront/getCmdHisto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: identifiants.identifiants && identifiants.identifiants.customer_id,
      }),
    }).then(response => response.json())
      .then(function (result) {
        dispatch(clearLoading(true))
        setOrders(result.filter(p => parseFloat(p.amount) > 0))
      }).catch(function (error) {
        dispatch(clearLoading(false))
        console.log("Request failed", error)
        logError('getCmdHisto catch', {error, identifiants: identifiants.identifiants})
      })
  }, [dispatch, identifiants.identifiants]);

  return (
    <>
      <SEO title="Mon espace personnel" />
      <div className="columns mr-2 has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB" }}>
        {orders && orders.length > 0 ? (
          <>
            <div className="commandes header column is-3">Référence de commande</div>
            <div style={{ borderLeft: "0" }} className={`commandes header column is-2`}>
              Date
              </div>
            <div style={{ borderLeft: "0" }} className="commandes header column is-2">
              Montant
              </div>
            <div style={{ borderLeft: "0" }} className="commandes header column is-2">
              Paiement
              </div>
            <div style={{ borderLeft: "0" }} className="commandes header column is-2">
              Etat
              </div>
            <div style={{ borderLeft: "0" }} className={`commandes header column `}></div>

          </>
        ) : (
            <span className="subtitlte is-3">Aucune commande</span>
          )}
      </div>

      {orders && orders.map((order, index) => (
        <div className="columns commandes line mr-2" key={index} style={{ background: "#fff" }}>
          <div className="commandes column is-3">
            <span className="is-hidden-desktop">
              Commande N°
                <b>{order.cmd_id}</b> du <b>{order.date}</b>
            </span>
            <span className="is-hidden-touch">{order.cmd_id}</span>
          </div>
          <div className="column is-2 is-hidden-touch">{order.date}</div>
          <div className="column is-2">
            <span className="is-hidden-desktop">Total :&nbsp;</span>
            {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(order.amount)}
          </div>
          <div className="column is-2">
            <span className="is-hidden-desktop">Paiement :&nbsp;</span>
            {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(order.payment)}
          </div>
          <div className="column is-2">
            <span>{GetStatus(order.cmd_status, order.cmd_saisie_livr, order.payment_status, order.livr_date)}</span>
          </div>
          <div className="column ">
            <Link to={`/apps/mon-compte/commande/${order.cmd_id}`}>Détails</Link>
            <Link className={`${parseInt(order.cmd_status) === 1 && parseInt(order.cmd_saisie_livr) === 1 && (order.cb_to_pay === "1" || order.no_cb_payment === "1") && order.amount > order.payment ? "" : "is-hidden"}`} to={`/apps/mon-compte/commande/${order.cmd_id}`}> / Payer</Link>
          </div>
        </div>
      ))}
    </>
  )
}

export default MesCommandes
