import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PaiementCmd from "./paiementCmd"
import { clearLoading, showLoading } from "../actions/index"
import { useSelector, useDispatch } from "react-redux"

export const DernieresCommandes = (props) => {
  const dispatch = useDispatch();
  const identifiants = useSelector(s => s.identifiants);

  const [orders, setOrders] = useState({});
  const today = (new Date()).toISOString().substr(0, 10);

  const GetStatus = (cmd_id, cmd_status, cmd_saisie_livr, payment_status, livr_date) => {
    if (props.attenteValidationBanque === cmd_id && payment_status === null) {
      return "Paiement en attente de validation par la banque"
    }
    if (payment_status !== null) {
      return payment_status
    }
    if (cmd_status === "0") {
      return "Panier non confirmé"
    }
    if (cmd_saisie_livr === "0") {
      if (livr_date < today) {
        return "";
      }
      return "Commandée"
    }
    if (cmd_saisie_livr === "1") {
      if (livr_date < today) {
        return "Livrée";
      }
      return "Préparée";
    }
  }


  useEffect(() => {
    const url = process.env.GATSBY_API_URL + "/public/tofront/getLastOrders";
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cmdPerson: identifiants.identifiants.customer_id
      })
    }).then(response => response.json())
      .then(function (result) {
        dispatch(clearLoading(true))
        setOrders(result);
      }).catch(function (error) {
        dispatch(clearLoading(false))
        console.log("Request failed", error)
      })
  }, [dispatch, identifiants.identifiants.customer_id]);

  return (
    <table className="table is-bordered">
      <thead>
        <tr>
          <th>Date</th>
          <th>Montant</th>
          <th>Paiement</th>
          <th>État</th>
        </tr>
      </thead>
      <tbody>
      {orders.length > 0 && orders.map(order => (
        <tr key={order.cmd_id}>
          <td>
            <Link to={`/apps/mon-compte/commande/${order.cmd_id}`}>
              {order.date}
            </Link>
          </td>
          <td>
            <Link to={`/apps/mon-compte/commande/${order.cmd_id}`}>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(order.amount)}
            </Link>
          </td>
          <td>
            <Link to={`/apps/mon-compte/commande/${order.cmd_id}`}>
              {props.attenteValidationBanque === order.cmd_id ? new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(order.amount) : new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(order.payment)}
            </Link>
          </td>
          <td>
            <Link to={`/apps/mon-compte/commande/${order.cmd_id}`}>
              {GetStatus(order.cmd_id, order.cmd_status, order.cmd_saisie_livr, order.payment_status, order.livr_date)}
            </Link>
            {(order.cb_to_pay === "1" || order.no_cb_payment === "1") && parseInt(order.cmd_saisie_livr) > 0 && props.attenteValidationBanque !== order.cmd_id && (order.amount - order.payment) > 0 ? <PaiementCmd total={order.amount - order.payment} zoneId={order.livr_zone_id} cmdId={order.cmd_id} /> : null}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}

export default DernieresCommandes
