import React from "react"
import Dashboard from "./dashboard"
import DonnesPerso from "./../components/profile/donneesPerso"
import Commandes from "./../components/profile/commandes"
import MesCotisations from "./../components/profile/cotisations"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import SEO from "./../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

export const Profile = props => {

  const identifiants = useSelector(s => s.identifiants)
  return (
    <>
      <SEO title="Mon compte" description="Mon compte" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Mon espace personnel AgroBioConso
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="columns" style={{marginLeft: 0, marginRight: 0}}>
          <div className="column is-3 mt-3" style={{paddingRight: 0, background: "#ccc"}}>
            <aside className="menu">
              <ul className="menu-list">
                <li>
                  <Link to="/apps/dashboard/" className={props.page === "dashboard" ? "is-active" : null}>
                    <span style={{ marginLeft: "1rem" }}>Tableau de bord</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right", marginTop: 2}} className="is-hidden-touch" />
                  </Link>
                </li>
                <li>
                  <Link to="/apps/mon-compte/commandes" className={props.page === "commandes" ? "is-active" : null}>
                    <span style={{ marginLeft: "1rem" }}>Mes commandes</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right", marginTop: 2}} className="is-hidden-touch" />
                  </Link>
                </li>
                <li>
                  <Link to="/apps/mon-compte/donneesPerso" className={props.page === "donneesPerso" ? "is-active" : null}>
                    <span style={{ marginLeft: "1rem" }}>Mes données personnelles</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right", marginTop: 2}} className="is-hidden-touch" />
                  </Link>
                </li>
                <li>
                  <Link to="/apps/mon-compte/cotisations" className={props.page === "cotisations" ? "is-active" : null}>
                    <span style={{ marginLeft: "1rem" }}>Mes cotisations</span> <FontAwesomeIcon icon={faChevronRight} style={{float: "right", marginTop: 2}} className="is-hidden-touch" />
                  </Link>
                </li>
              </ul>
            </aside>
          </div>
        
          <div className="column pattern-cross-dots mt-3 pb-5 pt-5 pl-5 pr-5" style={{ minHeight: "25rem" }}>
            {props.page === "dashboard" ? <Dashboard path="/apps/dashboard/" /> : null}
            {/* {props.page == "commandes" ? <Commandes commandes={identifiants.identifiants.orders} /> : ""} */}
            {props.page === "commandes" ? <Commandes dashboard={false}/> : null}

            {props.page === "donneesPerso" ? (
              <DonnesPerso profile={identifiants} donneesPersoATelecharger={identifiants.identifiants} />
            ) : (
              null
            )}
            {props.page === "cotisations" ? <MesCotisations dashboard={false}/> : null}
          </div> 
        </div>
      </section>
    </>
  )
}

export default Profile
