import React from "react"
import { Router } from "@reach/router"

import Layout from "../components/layout"
import Livraison from "../containers/livraison"
import ChoixCompte from "../containers/choixCompte"
import Profile from "../containers/profile"
import OrderDetails from "../containers/orderDetails"


const App = () => (
  <Layout>
    <Router>
      <Livraison path="/apps/producteur/produits/" />
      <ChoixCompte path="/apps/mon-compte/choix/" />
      <OrderDetails path="/apps/mon-compte/commande/:id_order" />
      <Profile path="/apps/mon-compte/" page="commandes" />
      <Profile path="/apps/mon-compte/commandes" page="commandes" />
      <Profile path="/apps/mon-compte/donneesPerso" page="donneesPerso" />
      <Profile path="/apps/mon-compte/cotisations" page="cotisations" />
      <Profile path="/apps/dashboard/" page="dashboard" />
    </Router>
  </Layout>
)

export default App
