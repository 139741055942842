import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import {logError} from "../services/log"

import { removeCmdAPI } from "../actions/panierAPI"
import { clearLoading, showLoading } from "../actions/index"
import PaiementCmd from "../components/paiementCmd"

export const OrderDetails = props => {
  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants.identifiants)
  const [order, setOrder] = useState({});

  const dataGroup = useStaticQuery(graphql`
    query dataCommandeDetails{
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_address
            group_ville
            group_horaire
            group_person
            group_zone_id
          }
        }
      }
    }
  `)

  const SousTotal = order && order.product ? 
    (order.product.reduce((acc, product) => 
      acc + Math.round((product.cmd_line_price * (order.cmd_saisie_livr === "1" ? product.cmd_line_qty_livr : product.cmd_line_quantity) * (1 - (order.cmd_discount || 0) / 100) + Number.EPSILON) * 100) / 100, 0)
      + parseFloat(order.cmd_frais_livr))
    : 0
  const remise = order && order.product ? 
    (order.product.reduce((acc, product) => 
      acc + Math.round((product.cmd_line_price * (order.cmd_saisie_livr === "1" ? product.cmd_line_qty_livr : product.cmd_line_quantity) * (-(order.cmd_discount || 0) / 100) + Number.EPSILON) * 100) / 100, 0)
    ) : 0
  const listGroup = dataGroup.allMysqlGroupe.edges.filter(legroupe => parseInt(legroupe.node.group_id) === parseInt(order.cmd_group_id))

  useEffect(() => {
    dispatch(showLoading())
    fetch(process.env.GATSBY_API_URL + "/public/tofront/getCmdDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cmd_id: props.id_order,
      }),
    }).then(response => response.json())
      .then(function (result) {
        dispatch(clearLoading(true))
        setOrder(result);
      }).catch(function (error) {
        dispatch(clearLoading(false))
        console.log("Request failed", error)
        logError('getCmdDetails catch', {error, idOrder: props.id_order})
      })
  }, [dispatch, props.id_order]);

  const DisplayPDF = cmd_id => {
    window.open(process.env.GATSBY_API_URL + "/public/tofront/invoice-pdf/" + cmd_id)
  }

  const today = (new Date()).toISOString().substr(0, 10);
  const GetStatus = (cmd_status, cmd_saisie_livr, payment_status, livr_date) => {
    if (payment_status !== null) {
      return payment_status
    }
    if (cmd_status === "0") {
      return "Panier non confirmé"
    }
    if (cmd_saisie_livr === "0") {
      if (livr_date < today) {
        return "";
      }
      return "Commandée"
    }
    if (cmd_saisie_livr === "1") {
      if (livr_date < today) {
        return "Livrée";
      }
      return "Préparée";
    }
  }

  const RemoveCmd = (cmd_id) => {
    dispatch(removeCmdAPI(cmd_id, identifiants.customer_id, identifiants.customer_pass))
    navigate(`/apps/mon-compte/`)
  }

  return (
    <>
      <SEO title={`Commande n°${order.cmd_id} du ${order.date}`} />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Details de la commande n°{order.cmd_id} du {order.date}
            </h1>
          </div>
        </div>
      </section>
      <section className="pattern-cross-dots pt-5 pb-5">
        <div className="container">
          <div role="button" className={`fakeLink ${order.livr_status === "5" ? "" : "is-hidden"}`} style={{ float: "right" }} tabIndex="0" onKeyPress={() => { }} onClick={() => { RemoveCmd(order.cmd_id) }}>Annuler ma commande</div>
          <div><Link to="/apps/mon-compte/"> &lt; Retour à la liste des commandes</Link></div>
          {(order.cb_to_pay === "1" || order.no_cb_payment === "1") && parseInt(order.cmd_saisie_livr) > 0 && (SousTotal - order.payment) > 0 ? <PaiementCmd total={SousTotal - order.payment} zoneId={order.livr_zone_id} cmdId={order.cmd_id} /> : null}
          <div className="card mt-5 p-5">
            {order.payment !== null ? (
              <p>
                <b>Moyen de paiement</b> : {order.payment}
              </p>
            ) : ("")}

            <b>État de votre commande</b> : {GetStatus(order.cmd_status, order.cmd_saisie_livr, order.payment_status, order.livr_date)}
            {order.cmd_saisie_livr === "1" ? (
              <button className="button" tabIndex="-2" onKeyPress={() => { }} onClick={() => { DisplayPDF(order.cmd_id) }} style={{float: "right"}}>
                Télécharger la facture
              </button>
            ) : (
                ""
              )}
            {listGroup && listGroup[0] ? <p><b>Point de retrait :</b> {listGroup[0].node.group_person} - {listGroup[0].node.group_address} {listGroup[0].node.group_ville} - {listGroup[0].node.group_horaire} </p> : ""}
          </div>

          <div className="card mt-5 p-5 mb-5">
            <p className="subtitle has-text-weight-bold almond is-size-4">Produits de votre commande </p>
            <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB", margin: 0 }}>
              <div className="panier header column is-6">Produit</div>
              <div style={{ borderLeft: "0" }} className={`panier header column is-${order.cmd_saisie_livr === "1" ? 1 : 2}`}>
                Quantité commandée
              </div>
              {order.cmd_saisie_livr === "1" && <div style={{ borderLeft: "0" }} className={`panier header column is-${order.cmd_saisie_livr === "1" ? 1 : 2}`}>
                Quantité livrée
              </div>}
              <div style={{ borderLeft: "0" }} className="panier header column is-2">
                Prix unitaire
              </div>

              <div style={{ borderLeft: "0" }} className="panier header column is-2">
                Total (TTC)
              </div>
            </div>

            {order.product && order.product.map((product, index) => (
              <div key={index} className="columns panier line" style={{ margin: 0 }}>
                <div className="column is-6" dangerouslySetInnerHTML={{ __html: product.product_name }} />
                <div className={`column is-${order.cmd_saisie_livr === "1" ? 1 : 2}`}>
                  <span className="is-hidden-desktop">Quantité commandée :&nbsp;</span>
                  {product.cmd_line_quantity}{" "}
                </div>
                {order.cmd_saisie_livr === "1" && <div className={`column is-${order.cmd_saisie_livr === "1" ? 1 : 2}`}>
                  <span className="is-hidden-desktop">Quantité livrée :&nbsp;</span>
                  {product.cmd_line_qty_livr}{" "}
                </div>}
                <div className="column is-2">
                  <span className="is-hidden-desktop">Prix unitaire :&nbsp;</span>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(product.cmd_line_price)}
                </div>
                <div className="column is-2">
                  <span className="is-hidden-desktop">Total :&nbsp;</span>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(order.cmd_saisie_livr === "1" ? product.cmd_line_price * product.cmd_line_qty_livr : product.cmd_line_price * product.cmd_line_quantity)}
                </div>
              </div>
            ))}
            {remise ? <div className="columns mt-2">
              <div className="column is-offset-6 is-6 has-text-weight-bold">
                <table>
                  <tbody>
                    <tr>
                      <td>Remise commerciale ({order.cmd_discount}%)</td>
                      <td className="has-text-right">
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(parseFloat(remise))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> : null}
            {parseFloat(order.cmd_frais_livr) > 0 ? <div className="columns mt-2 mb-0">
              <div className="column is-offset-6 is-6 has-text-weight-bold">
                <table style={{ marginBottom: 0 }}>
                  <tbody>
                    <tr>
                      <td>Frais de livraison</td>
                      <td className="has-text-right">
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(parseFloat(order.cmd_frais_livr))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> : null}
            <div className="columns mt-2">
              <div className="column is-offset-6 is-6 has-text-weight-bold">
                <table>
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td className="has-text-right">
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(parseFloat(SousTotal))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OrderDetails
